import { CSSProperties } from "react"
import s from "./Spinner.module.css"

type SpinnerProps = {
  /** Human-readable text alternative to current status */
  ariaValuetext?: string
  /** Overwrite styles */
  style?: CSSProperties & { [property: string]: string } // extension is for CSS custom props
}

/**
 * Spinners are used to show the progress of an indeterminate operation as spinning circle.
 *
 * Size is based on current font size. Simply set font-size property on the spinner itself or on a parent element.
 *
 * Colors, circle width and speed can be controlled by CSS custom properties (see below).
 *
 * @example
 * import Spinner from "@ui/atoms/Spinner"
 *
 * <Spinner style={{ fontSize: "3rem", "--spinner-track-width": "5px" }} />
 *
 * @cssproperty --spinner-track-width - The width of the track. Defaults to "2px"
 * @cssproperty --spinner-track-color - The color of the track. Default is "rgb(128 128 128 / 25%)" (grey with 25% opacity)
 * @cssproperty --spinner-indicator-color - The color of the spinner's indicator. Default is "var(--color-primary, rgb(0 0 0 / 25%))" (black with 25% opacity)
 * @cssproperty --spinner-speed - The time it takes for the spinner to complete one animation cycle as CSS `animation-duration` value. Default is "2s"
 */
const Spinner = (props: SpinnerProps) => {
  const { ariaValuetext: ariaLabel = "Loading" } = props
  return (
    <div className={s.container} style={props.style}>
      <svg className={s.spinner} role="progressbar" aria-valuetext={ariaLabel}>
        <circle className={s.track} />
        <circle className={s.indicator} />
      </svg>
    </div>
  )
}

export default Spinner
