"use client"

import Message from "@ui/atoms/Message"
import { isObject } from "lib/guards"
import { urlFragmentToObj } from "lib/urlUtils"
import { useEffect, useState } from "react"

// import * as s from "./UrlHashError.module.scss"

type UrlHashError = {
  error: string
  error_code: string
  error_description: string
}

function isUrlHashError(val: unknown): val is UrlHashError {
  if (!isObject(val)) {
    return false
  }
  return "error" in val
}

type SupabaseUrlHashErrorProps = {}

/**
 * Reads Supabase messages and codes from URL hash and renders them user friendly.
 * Used for sign in, invite confirm, password reset and possibly other user flows
 * where Supabase redirects back to our service, possibly with a error or other info
 * passed in URL fragment.
 */
const SupabaseUrlHashError = (props: SupabaseUrlHashErrorProps) => {
  // const [hash, setHash] = useState<URLSearchParams | null>(null);

  const [urlError, setUrlError] = useState<UrlHashError | null>(null)

  useEffect(() => {
    // On client component mount, read Supabase messages and codes from URL hash
    // and update context to include those if error values found

    const hashObj = urlFragmentToObj(window.location.hash)

    if (isUrlHashError(hashObj)) {
      setUrlError(hashObj)
    }
  }, [])

  return urlError ? (
    <Message type="error" heading="Autentikaatiovirhe">
      {urlError.error_description}
    </Message>
  ) : null
}

export default SupabaseUrlHashError
