"use client"

import Message from "@ui/atoms/Message"
import Spinner from "@ui/atoms/Spinner"
import SignInForm from "components/SignInForm"
import useSession from "hooks/useSession"
import { createSupabaseBrowserClient } from "lib/supabase/browserClient"
import Link from "next/link"
import { useRouter } from "next/navigation"
import { useState } from "react"

type SignInProps = {}

const SignIn = (props: SignInProps) => {
  const router = useRouter()
  const [authError, setAuthError] = useState<string | null>(null)
  const supabase = createSupabaseBrowserClient()
  const { sessionReady } = useSession()

  if (!sessionReady) {
    return (
      <Spinner style={{ fontSize: "5rem", "--spinner-track-width": "10px" }} />
    )
  }

  const handleSignIn = async (email: string, password: string) => {
    const { /*data,*/ error } = await supabase.auth.signInWithPassword({
      email,
      password,
    })

    if (error) {
      if (error.message === "Invalid login credentials") {
        setAuthError("Käyttäjätunnus tai salasana väärin")
      } else {
        setAuthError(error.message)
      }
    } else {
      router.refresh()
    }
  }

  return (
    <>
      <SignInForm onSignIn={handleSignIn} />
      {authError && (
        <Message type="error" heading="Virhe">
          {authError}
          <br />
          <Link href="/password-reset">Unohtuiko salasana?</Link>
        </Message>
      )}
    </>
  )
}

export default SignIn
