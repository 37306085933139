import { zodResolver } from "@hookform/resolvers/zod"
import { useForm, UseFormProps } from "react-hook-form"
import { TypeOf, ZodSchema } from "zod"

/**
 * Same interface as react-hook-formreact-hook-form `useForm()` first argument but without `resolver`.
 * Type of `resolver` is infered from given `schema` which must satisfy type `ZodSchema`
 */
interface UseZodFormProps<Z extends ZodSchema>
  // exclude `resolver` but add everything else from `UseFormProps`
  extends Exclude<UseFormProps<TypeOf<Z>>, "resolver"> {
  schema: Z // get the type of `schema` using generics, the type extends `ZodSchema`
}

/**
 * Like react-hook-form `useForm()` but with Zod schema based resolver that is automatically typed
 */
export const useZodForm = <Z extends ZodSchema>({
  schema,
  ...formProps
}: UseZodFormProps<Z>) =>
  useForm({
    ...formProps,
    resolver: zodResolver(schema),
  })
