import { ComponentPropsWithoutRef } from "react"
import s from "./Button.module.css"

interface ButtonProps extends ComponentPropsWithoutRef<"button"> {}

const Button = (props: ButtonProps) => {
  const { children, ...buttonProps } = props
  return (
    <button {...buttonProps} className={s.button}>
      {children}
    </button>
  )
}

export default Button

interface ButtonLinkProps extends ComponentPropsWithoutRef<"a"> {}

/**
 * Native <a> link that looks like a `<Button />`.
 */
export const ButtonLink = (props: ButtonLinkProps) => {
  const { children, ...buttonProps } = props
  return (
    <a {...buttonProps} className={s.button}>
      {children}
    </a>
  )
}
