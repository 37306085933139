import iconSuccess from "@material-design-icons/svg/filled/done.svg"
import iconError from "@material-design-icons/svg/filled/error.svg"
import iconNeutral from "@material-design-icons/svg/filled/info.svg"
import iconWarning from "@material-design-icons/svg/filled/warning.svg"
import Image from "next/image"
import React from "react"
import s from "./Message.module.css"

type MessageProps = {
  type: "error" | "warning" | "success" | "neutral"
  heading: string
  children?: React.ReactNode
}

const icons: Record<MessageProps["type"], React.ReactNode> = {
  error: <Image src={iconError} alt="Error icon" priority />,
  warning: <Image src={iconWarning} alt="Warning icon" priority />,
  success: <Image src={iconSuccess} alt="Success icon" priority />,
  neutral: <Image src={iconNeutral} alt="Neutral icon" priority />,
  //   error: iconError,
  //   warning: iconWarning,
  //   success: iconSuccess,
  //   neutral: iconNeutral,
}

const Message = (props: MessageProps) => {
  const { heading, type = "neutral" } = props

  return (
    <aside className={`${s.container} ${s[type]}`}>
      <header className={s.header}>
        <span>{icons[type]}</span>
        <div className={s.heading}>{heading}</div>
      </header>
      {props.children && <div>{props.children}</div>}
    </aside>
  )
}

export default Message
