"use client"

import Button from "@ui/atoms/Button"
import Form from "@ui/form/Form"
import FormInput from "@ui/form/FormInput"
import { useZodForm } from "hooks/useZodForm"
import { z } from "zod"
// import s from "./LoginForm.module.css"

export const signInFormSchema = z.object({
  /** User sign in email address */
  email: z.string().email(),
  /** User sign in password */
  password: z.string(),
})

type SignInFormProps = {
  onSignIn: (email: string, password: string) => void
}

const SignInForm = (props: SignInFormProps) => {
  const form = useZodForm({ schema: signInFormSchema })

  return (
    <Form
      form={form}
      onSubmit={({ email, password }) => props.onSignIn(email, password)}
    >
      <FormInput
        label="Sähköposti"
        placeholder="nimi@yritys.fi"
        {...form.register("email")}
      />
      <FormInput
        label="Salasana"
        type="password"
        {...form.register("password")}
      />
      <Button>Kirjaudu</Button>
    </Form>
  )
}

export default SignInForm
