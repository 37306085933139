import { ComponentProps } from "react"
import s from "./FormFieldset.module.css"

interface FormFieldsetProps extends ComponentProps<"fieldset"> {
  legend?: string
  structural?: boolean
  // children: React.ReactNode;
}

const FormFieldset = (props: FormFieldsetProps) => {
  const { legend, children, structural = false, ...fieldsetProps } = props
  return (
    <fieldset
      {...fieldsetProps}
      className={`${s.fieldset} ${structural ? s.structural : s.normal}`}
    >
      {legend && <legend className={s.legend}>{legend}</legend>}
      {children}
    </fieldset>
  )
}

export default FormFieldset
