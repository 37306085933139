export function urlFragmentToObj(urlFragment: string) {
  // remove possible "#" at the start of the URL fragment string
  const cleanHash =
    urlFragment.charAt(0) === "#" ? urlFragment.slice(1) : urlFragment
  if (!cleanHash) {
    // empty string, falsy etc means we're not interested
    return
  }
  // parse and decode
  const hashParams = new URLSearchParams(cleanHash)
  // convert to object
  return Object.fromEntries(hashParams)
}
