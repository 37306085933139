import FormFieldset from "@ui/form/FormFieldset"
import { ComponentProps } from "react"
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  UseFormReturn,
} from "react-hook-form"

interface FormProps<T extends FieldValues>
  extends Omit<ComponentProps<"form">, "onSubmit"> {
  form: UseFormReturn<T>
  onSubmit: SubmitHandler<T>
}

/**
 * A react-hook-form based form.
 *
 * Wraps everything in `<FormProvider />`, wraps onSubmit with `rhf.handleSubmit()` and
 * wraps form childs to a `<fieldset />` that disables every field while form is submitting.
 */
const Form = <T extends FieldValues>({
  form,
  onSubmit,
  children,
  ...props
}: FormProps<T>) => (
  <FormProvider {...form}>
    <form onSubmit={form.handleSubmit(onSubmit)} {...props}>
      <FormFieldset disabled={form.formState.isSubmitting} structural>
        {children}
      </FormFieldset>
    </form>
  </FormProvider>
)

export default Form
