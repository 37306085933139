import { Session } from "@supabase/supabase-js"
import { createSupabaseBrowserClient } from "lib/supabase/browserClient"
import { useEffect, useState } from "react"

export default function useSession() {
  const { auth } = createSupabaseBrowserClient()
  const [session, setSession] = useState<{
    session: Session | null
    sessionReady: boolean
  }>({ session: null, sessionReady: false })

  useEffect(() => {
    async function getSession() {
      const {
        data: { session },
        error,
      } = await auth.getSession()
      if (error) {
        console.error(error)
      }
      setSession({ session, sessionReady: true })
    }

    getSession()
  }, [auth])

  return session
}
