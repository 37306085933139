import { PropsWithChildren, useId } from "react"
import { useFormContext } from "react-hook-form"
import s from "./FormField.module.css"

export interface UseFormFieldProps extends PropsWithChildren {
  name: string
  label: string
  hint?: string
  hintValidation?: string
  /** Hide the field both visually and from screen readers */
  hidden?: boolean
}

/**
 * Splits given props to `formFieldProps` and `childProps`.
 *
 * Adds the same `id`, created with `useId()`, to both sets.
 */
export const useFormField = <P extends HTMLElement>(
  props: React.HTMLAttributes<P> & UseFormFieldProps
) => {
  const { label, name, hint, hintValidation, hidden, ...otherProps } = props
  const id = useId()

  return {
    formFieldProps: { id, name, label, hint, hintValidation, hidden },
    childProps: { ...otherProps, id, name, hidden },
  }
}

interface FormFieldProps extends UseFormFieldProps {
  id: string
}

const FormField = (props: FormFieldProps) => {
  const ctx = useFormContext()
  const state = ctx.getFieldState(props.name, ctx.formState)

  // we use aria-hidden to hide both visually and from screen readers
  // that is, `hidden` props means "fully hidden" because that's our only use case so far

  return (
    <div className={s.field}>
      <label htmlFor={props.id} className={s.label} aria-hidden={props.hidden}>
        {props.label}
      </label>
      {props.children}
      {props.hint && <p className={s.hint}>{props.hint}</p>}
      {props.hintValidation && <p className={s.hint}>{props.hintValidation}</p>}
      {state.error && <p className={s.error}>{state.error.message}</p>}
    </div>
  )
}

export default FormField
