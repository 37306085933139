import { ComponentProps, forwardRef } from "react"
import FormField, { UseFormFieldProps, useFormField } from "./FormField"
import s from "./FormInput.module.css"

interface FormInputProps extends UseFormFieldProps, ComponentProps<"input"> {
  name: string
  label: string
  hint?: string
}

const FormInput = forwardRef<HTMLInputElement, FormInputProps>(
  (props, forwardRef) => {
    const { formFieldProps, childProps } = useFormField<HTMLInputElement>(props)

    return (
      <FormField {...formFieldProps}>
        <input {...childProps} className={s.input} ref={forwardRef} />
      </FormField>
    )
  }
)
FormInput.displayName = "FormInput"

export default FormInput
